<template>
  <template v-if="loading">
    <skeleton-loader
      width="10px"
      height="80px"
      class-name="rounded w-full mt-5"
      v-for="i in 5"
      :key="i"
    />
  </template>
  <template v-else-if="accounts.length">
    <div v-for="(account, index) in accounts" :key="index">
      <div class="inline-flex items-center">
        <div class="round">
          <ion-icon name="albums" class="text-invest-dark" />
        </div>
        <div class="flex flex-col ml-5 text-sm">
          <h5>{{ account.account_name }}</h5>
          <span class="mt-2 text-xs text-invest-dark">
            {{ account.account_number }} • {{ getBankName(account.bank_name) }}
          </span>
        </div>
      </div>
      <div class="mt-5 mb-5 border-b"></div>
    </div>
  </template>
  <template v-else>
    <div class="flex flex-col">
      <p class="mt-3 text-10 text-invest-dark">
        You currently have no bank accounts.
      </p>
    </div>
  </template>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { AccountProp } from '@/types/Account';
import { useStore } from 'vuex';

export default defineComponent({
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    accounts: {
      type: Array as PropType<AccountProp[]>,
      default: () => [] as AccountProp[],
    },
  },
  emits: ['bankAccounts'],
  setup: () => {
    const { state } = useStore();

    const getBankName = (bankCode: string): string => {
      return state?.utils?.banks?.find((bank: any) => bank?.code === bankCode)
        ?.name;
    };

    return { getBankName };
  },
});
</script>
