
import { defineComponent, PropType } from 'vue';
import { AccountProp } from '@/types/Account';
import { useStore } from 'vuex';

export default defineComponent({
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    accounts: {
      type: Array as PropType<AccountProp[]>,
      default: () => [] as AccountProp[],
    },
  },
  emits: ['bankAccounts'],
  setup: () => {
    const { state } = useStore();

    const getBankName = (bankCode: string): string => {
      return state?.utils?.banks?.find((bank: any) => bank?.code === bankCode)
        ?.name;
    };

    return { getBankName };
  },
});
